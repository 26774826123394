import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";
import i18n from "i18next";

import { readState } from "@/__main__/app-state.mjs";
import ProfileMaps from "@/game-eft/components/ProfileMaps.jsx";
import ProfileOverview from "@/game-eft/components/ProfileOverview.jsx";
import ProfileWeapons from "@/game-eft/components/ProfileWeapons.jsx";
import {
  SelectFaction,
  SelectMode,
  SelectSeason,
} from "@/game-eft/components/Selects.jsx";
import { FactionRE } from "@/game-eft/constants/constants.mjs";
import getProfileAvatar from "@/game-eft/utils/getProfileAvatar.mjs";
import useFaction from "@/game-eft/utils/use-faction.mjs";
import useFactions from "@/game-eft/utils/use-factions.mjs";
import BlitzLogoLoading from "@/shared/BlitzLogoLoading.jsx";
import Container from "@/shared/ContentContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import SharedProfile from "@/shared/Profile.jsx";
import { ProfileLayout } from "@/shared/Profile.style.jsx";
import { useEvalState } from "@/util/eval-state.mjs";
import isObject from "@/util/is-object.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const TABS = {
  OVERVIEW: "overview",
  WEAPONS: "weapons",
  MAPS: "maps",
};

function Main() {
  const state = useSnapshot(readState);
  const { t } = useTranslation();
  const {
    parameters: [profileId, tab],
  } = useRoute();
  // Raw state
  const $profile = state.eft.profiles?.[profileId];
  const liveGame = state.eft?.liveGame;
  // Eval state
  const profile = useEvalState($profile);
  const faction = useFaction(profileId);
  const profiles = Object.values(liveGame?.registerPlayer ?? {}).filter(
    (i) => i.player.account_id === profileId,
  );
  const pmcProfile = profiles.find((i) =>
    [FactionRE.bear, FactionRE.usec].some((j) => j === i.player.side),
  );
  const pmcData = isObject(pmcProfile?.player.profile)
    ? pmcProfile?.player.profile
    : undefined;
  const playerFactionStats = useFactions(profileId);
  const playerFactionStat = playerFactionStats.find(
    (i) => i.faction === faction,
  );
  // Tabs
  const baseUrl = `/eft/profile/${profileId}`;
  const tabs = useMemo(
    () => [
      {
        name: t("csgo:navigation.overview", "Overview"),
        id: TABS.OVERVIEW,
        url: baseUrl,
      },
      ...(profile
        ? [
            {
              name: t("csgo:navigation.weapons", "Weapons"),
              id: TABS.WEAPONS,
              url: `${baseUrl}/${TABS.WEAPONS}`,
            },
            {
              name: t("csgo:navigation.maps", "Maps"),
              id: TABS.MAPS,
              url: `${baseUrl}/${TABS.MAPS}`,
            },
          ]
        : []),
    ],
    [baseUrl, profile, t],
  );
  const links = useMemo(
    () =>
      tabs.map((tab) => ({
        url: tab.url,
        text: tab.name,
      })),
    [tabs],
  );
  const SelectedTab = useMemo(() => {
    switch (tab) {
      case TABS.WEAPONS:
        return <ProfileWeapons />;
      case TABS.MAPS:
        return <ProfileMaps />;
      default:
        return <ProfileOverview />;
    }
  }, [tab]);
  return (
    <ProfileLayout>
      {$profile ? (
        <>
          <PageHeader
            imageRound
            title={
              profile?.username ||
              pmcData?.info.main_profile_nickname ||
              pmcData?.nickname
            }
            accentText={String(playerFactionStat?.level ?? "")}
            image={getProfileAvatar("")}
            links={links}
          />
          {!tab && profile && (
            <Container>
              <div className="flex gap-2">
                <SelectMode />
                <SelectFaction />
                <SelectSeason />
              </div>
            </Container>
          )}
          <Container>
            <SharedProfile>{SelectedTab}</SharedProfile>
          </Container>
        </>
      ) : (
        <Container>
          <div className="relative">
            <Card loading style={{ height: "var(--sp-48)" }} />
            <div className="absolute-center">
              <BlitzLogoLoading />
            </div>
          </div>
        </Container>
      )}
    </ProfileLayout>
  );
}

export function meta([profileId]) {
  const profile = readState.eft.profiles[profileId];
  const username =
    profile instanceof Error || !profile
      ? i18n.t("common:player", "Player")
      : profile.username;
  return {
    title: [
      `eft:meta.profile.title`,
      `{{username}}'s - EFT Performance Overview`,
      { username },
    ],
    description: [
      `eft:meta.profile.description`,
      `View {{username}}'s EFT profile and see how they perform.`,
      { username },
    ],
  };
}

export default Main;
