import React from "react";

import useMatches from "@/game-eft/utils/use-matches.mjs";
import HeadShotStatsShared from "@/shared-fps/HeadShotStats.jsx";
import { sanitizeNumber } from "@/util/helpers.mjs";

export default function HeadShotStats({ profileId }: { profileId: string }) {
  const matches = useMatches(profileId);
  const lastNStats = matches.reduce(
    (acc, cur) => {
      const player = cur.playerMatches.find(
        (i) => i.player.accountId === profileId,
      );
      if (!player) return acc;
      acc.headshots += player.hitsHead;
      acc.bodyshots += player.hitsBody;
      acc.chestshots += player.hitsChest;
      acc.stomachshots += player.hitsStomach;
      acc.legshots += player.hitsLeftLeg + player.hitsRightLeg;
      return acc;
    },
    {
      headshots: 0,
      bodyshots: 0,
      chestshots: 0,
      stomachshots: 0,
      legshots: 0,
    },
  );
  const hitTrend = React.useMemo<
    Array<{
      index: number;
      hsPercent: number;
      lastNAvgHS: number;
      imgUrl?: string;
    }>
  >(() => {
    const hitTrend = [];
    for (let i = 0; i < matches.length; i += 1) {
      const match = matches[i];
      const player = match.playerMatches.find(
        (j) => j.player.accountId === profileId,
      );
      hitTrend.push({
        index: i,
        hsPercent: sanitizeNumber((player.hitsHead / player.totalShots) * 100),
        lastNAvgHS: 0, // Calculated after
      });
    }
    const avg = sanitizeNumber(
      hitTrend.reduce((acc, cur) => acc + cur.hsPercent, 0) / hitTrend.length,
    );
    for (const i of hitTrend) i.lastNAvgHS = avg;
    return hitTrend;
  }, [matches, profileId]);

  return (
    <HeadShotStatsShared
      matches={hitTrend}
      lastNStats={lastNStats}
      hiddenLegshots={false}
      hideHits={false}
      // @ts-ignore field exists, bad JSX inherited types in a TSX file
      splitTorso={true}
    />
  );
}
